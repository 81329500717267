<template>
  <div style="font-family:times new roman;">
    <div class="no-print bg-black" style="color:White;">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-2 mt-4">
            <button
              type="button"
              @click="back()"
              class="btn btn-sm btn-outline-danger no-print "
            ><span class="typcn typcn-arrow-left"></span> Go Back!</button>
          </div>
          <div class="col-sm-6">
            <button type="button" @click="print()" class="btn btn-sm btn-primary float-right pr-4 mt-4"><span class="typcn typcn-printer"></span> Print</button>
          </div>
        </div>
      </div>
      <hr class="style13" />
    </div>
    <!--  -->
    <!-- DIMULAI DARI SINI PRINT PAGE -->
    <!--  -->
    <div style>
      <div class="row justify-content-center">
        <div class="col-3">
            <img
        :src="$store.state.data.toko.logo"
        class="kinoLightBox img-fluid rounded-top z-depth-2"
        style="margin-left:10px;position:absolute;left:100px;height:126px;"
        alt="no found"
      />
        </div>
        <div class="col-6">
         <p class="pl-3 text-center" style="font-size:32px;line-height:1.5;line-spacing:3px;">
           <!-- TOKO -->
           <span class="font-semibold">Toko {{$store.state.data.toko.nama_toko}}</span> 
            <br />
          </p>
          <p
            class="pl-3 text-center"
            style="font-size:18px;"
          ></p>
      <p
        class="text-center"
        style="font-size:18px;"
      >{{$store.state.data.toko.alamat}}</p>
            <br />
            <br />
        </div>
        <div class="col-3"></div>
      </div>
      <img src="@/static/photo/line.jpg" class="img-fluid" style="width:100%;height:20px;" />
          <br>
      
     <!-- BANYAK DATA -->
     <div v-if="nofakturs.length>0">
         <div class="row justify-content-center">
           <div class="col-md-10">
             
            <div class="rounded-lg shadow p-3 mb-4" v-for="(item, index) in nofakturs" :key="index+'nofakturs'">
            <br>
            <table class="table table-sm table-bordered">
                <tr>
                    <td class="font-bold">Tanggal</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].tanggal}}</td>
                     <td class="font-bold">Beli Lewat</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].beli}}</td>
                </tr>
               
                <tr>
                
                    <!-- <td class="font-bold">Supplier</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].supplier}}</td> -->
                    <td class="font-bold">Pasien</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].pelanggan}}</td>
                    <td class="font-bold">Nota</td>
                    <td class="font-bold">:</td>
                    <td class="font-bold">{{item.data[0].nofaktur}}</td>
                </tr>
                <tr>
                    <td class="font-bold">Staff</td>
                    <td class="font-bold">:</td>
                    <td class="" colspan="6">
                      <div class="row pb-3 pr-3">
                        <div class="col-6 p-2 capitalize shadow" v-if="item.data[0].nama_admin">
                          <span class="font-semibold">Frontliner</span> : {{item.data[0].nama_admin}}
                        </div>
                        <div class="col-6 p-2 capitalize shadow" v-if="item.data[0].nama_dokter">
                          <span class="font-semibold">Dokter</span> : {{item.data[0].nama_dokter}}
                        </div>
                        <div class="col-6 p-2 capitalize shadow" v-if="item.data[0].nama_nurse">
                          <span class="font-semibold">Nurse</span> : {{item.data[0].nama_nurse}}
                        </div>
                        <div class="col-6 p-2 capitalize shadow" v-if="item.data[0].nama_facial">
                          <span class="font-semibold">Facial Therapist</span> : {{item.data[0].nama_facial}}
                        </div>
                        <div class="col-6 p-2 capitalize shadow" v-if="item.data[0].nama_apoteker">
                          <span class="font-semibold">Apoteker</span> : {{item.data[0].nama_apoteker}}
                        </div>
                      </div>
                    </td>
                </tr>
            </table>
            <hr>
            <table class="table table-sm table-bordered table-striped">
                <tr>
                    <td>Barcode</td>
                    <td>Barang/Produk</td>
                    <td>Jumlah</td>
                    <td>Diskon</td>
                    <td>Harga</td>
                    <td>Total</td>
                </tr>
                <tr v-for="(item2, index2) in item.data" :key="index2+'table'">
                    <td>{{item2.barcode}}</td>
                    <td>{{item2.nama_produk}}</td>
                    <td>{{item2.jumlah}}</td>
                    <td>{{item2.diskon}}</td>
                    <td>{{item2.harga}}</td>
                    <td>
                        {{item2.total}}
                    </td>
                </tr>
                <tr>
                    <td colspan="4">TOTAL</td>
                    <td colspan="4">{{item.data[0].total_seluruh}}</td>
                </tr>
            </table>
        </div>
        <div class="rounded-lg shadow p-3 mb-4">
              <table class="table table-sm table-bordered table-striped">
                <tr>
                  <td class="font-bold">Total Semua Transaksi Penjualan</td>
                  <td class="font-bold"> : </td>
                  <td class="font-bold">{{$store.state.data.seluruh}}</td>
                </tr>
              </table>
            </div>
           </div>
         </div>
      <!-- TABLE END -->
     </div>
      <br />
      <br />
      <!-- TTD -->
      <!-- TTD -->
      <!-- TTD -->
      <div class="float-right text-2xl" style="padding-right:70px;">
        <p>Banjarbaru, {{format(new Date())}}</p>
        <p class="text-center">Atasan langsung</p>
        <br />
        <br />
        <br />

        <p class="text-2xl text-center" style="">{{$store.state.data.toko.owner}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
var id = require("date-fns/locale/id");
export default {
  data() {
    return {
      datanya:[],
      pembatas:1,
      thnya: [],
      records: "",
      key: [],
      keys: [],
      nofakturs:[]
    };
  },
  methods: {
    print() {
      document.addEventListener(
        "deviceready",
        function () {
          // cordova.plugins.printer is now available
          cordova.plugins.printer.print();
        },
        false
      );
      window.print();
    },
    back() {
      this.$router.go(-1);
    },
    format(date) {
      return this.$datefns.format(date, "DD MMMM YYYY");
    }
  },
  mounted() {
    // sdb.collection("app_kasir_toko",false).doc().select(`select * from app_kasir_toko`).then(res => {
    //     console.log(res);
    // });
    this.nofakturs=this.$store.state.data.nofakturs;
    // this.datanya=this.$store.state.data.report_masuk;
    // this.keys=Object.keys(this.$store.state.data.report_masuk[0])
    this.$forceUpdate();
   
  }
};
</script>
<style scoped>
.setting {
  padding: 10px;
  line-heigth: 1.5;
}
td {
  font-size: 19px;
  line-heigth: 1.5;
}
@page {
  size: auto; /* auto is the initial value */

  /* this affects the margin in the printer settings */
  /* margin: 2cm 2cm 3cm 3cm; */
  margin:10%;
}
@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }

    .table-print {
        border: 1px solid black;
    }
}
body {
  /* this affects the margin on the content before sending to printer */
  margin: 2cm 2cm 3cm 3cm;
  margin: 0px;
}
</style>